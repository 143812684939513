<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title class="pa-2 text-h6 mc-font"
          ><strong>{{ title }}</strong></v-toolbar-title
        >
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4">
        <div :style="{ fontSize: '1.3em', lineHeight: '1.6em', textAlign: 'justify' }">
          {{ message }}
        </div>
      </v-card-text>
      <v-card-actions class="pt-0 mt-8 flex-row-reverse text-right">
        <v-btn class="mx-2" color="primary darken-1" depressed @click.native="agree">{{
          this.acceptButtonText ? this.acceptButtonText : $t('accept')
        }}</v-btn>
        <v-btn v-if="!hideCancelButton" color="grey" text @click.native="cancel">{{
          $t('reject')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  props: {
    hideCancelButton: Boolean,
    acceptButtonText: String
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 400,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
